import { createRouter, createWebHashHistory } from "vue-router";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      // component: () => import('../pages/index/index.vue'),
      redirect: "/hotel/hfrd"
    },
    {
      path: '/hotel',
      name: 'hotel',
      component: () => import('../pages/hotel/index.vue'),
      children: [
        {
          path: 'hfrd',
          name: 'hfrd',
          component: () => import('../pages/hotel/hfrd/index.vue'),
        },
        {
          path: 'doorwash',
          name: 'doorwash',
          component: () => import('../pages/hotel/doorwash/index.vue'),
        },
        {
          path: 'lobby',
          name: "lobby",
          component: () => import('../pages/hotel/lobby/index.vue')
        },
        {
          path: 'bandbwash',
          name: 'bandbwash',
          component: () => import('../pages/bandb/wash/index.vue'),
        },
        {
          path: 'picking',
          name: 'picking',
          component: () => import('../pages/hotel/picking/index.vue'),
        },
      ]
    },
    {
      path: '/factory',
      name: 'factory',
      component: () => import('../pages/factory/index.vue'),
      children: [
        {
          path: 'washboard',
          name: 'washboard',
          component: () => import('../pages/factory/washboard/index.vue'),
        },
        {
          path: 'washboard1',
          name: 'washboard1',
          component: () => import('../pages/factory/demo/index.vue'),
        },
        {
          path: 'jensen',
          name: 'jensen',
          component: () => import('../pages/factory/jensen/index.vue'),
        },
        {
          path: 'deskwash',
          name: 'deskwash',
          component: () => import('../pages/factory/deskwash/index.vue'),
        },
        {
          path: 'deskrecv',
          name: 'deskrecv',
          component: () => import('../pages/factory/deskrecv/index.vue'),
        },
        {
          path: 'deskpack',
          name: "deskpack",
          component: () => import('../pages/factory/deskpack/index.vue'),
        },
        {
          path: 'userpack',
          name: 'userpack',
          component: () => import('../pages/factory/deskpack/userpack'),//手动打包
        },
        {
          path: 'hotel_linen',
          name: 'hotel_linen',
          component: () => import('../pages/factory/deskpack/userpack/hotel_linen.vue'),//酒店布草
        },
        {
          path: 'chippack',
          name: 'chippack',
          component: () => import('../components/factory_deskpack.vue'),
        },
        {
          path: 'deskhand',
          name: "deskhand",
          component: () => import('../pages/factory/deskhand/index.vue')
        },
        {
          path: 'boxwash',
          name: "boxwash",
          component: () => import('../pages/factory/boxwash/index.vue')
        },
        {
          path: 'boxcheck',
          name: "boxcheck",
          component: () => import('../pages/factory/boxcheck/index.vue')
        },
        {
          path: 'boxfeed',
          name: "boxfeed",
          component: () => import('../pages/factory/boxfeed/index.vue')
        },
        {
          path: 'boxpack',
          name: "boxpack",
          component: () => import('../pages/factory/boxpack/index.vue')
        }
      ]
    },
    {
      path: '/bandb',
      name: 'bandb',
      component: () => import('../pages/bandb/index.vue'),
      children: [
        {
          path: 'bandbwash',
          name: 'bandbwash',
          component: () => import('../pages/bandb/wash/index.vue'),
        },
        {
          path: 'bandbhand',
          name: 'bandbhand',
          component: () => import('../pages/bandb/hand/index.vue'),
        }
      ]
    },
    {
      path: '/3d',
      name: '3d',
      component: () => import('../pages/3d/index.vue'),
      children: [
        {
          path: 'humanbody',
          name: 'humanbody',
          component: () => import('../pages/3d/humanbody/index.vue')
        },
        {
          path: 'particle',
          name: 'particle',
          component: () => import('../pages/3d/particle/index.vue')
        }
      ]
    }
  ]
})
export default router